<!-- Error demo pages -->
<template>
  <transition name="slide">
    <router-view></router-view>
  </transition>
</template>

<script>
export default {
  components: {}
};
</script>
